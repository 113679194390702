<template>
  <div class="tab-container">
    <div class="tab-container-keys no-print">
      <ul class="nav" v-click-outside="() => handleOpenDropdown(currentTab)">
        <li
          :style="cssVars"
          v-for="(key, index) in Object.keys(tabs)"
          :key="index"
          class="keys"
          @click="handleOpenDropdown(key)"
          
        >
         <span>{{ key }}</span> 

        <ChevronDown class="icon" v-bind:class="{ rotateUp: openedCategories === key, rotateDown: openedCategories !== key}"/>

        <div class="dropdown-container" v-if="openedCategories === key">
          <ul class="dropdown" >
            <li v-for="(subcategory, index) in tabs[key]" :key="index" @click="changeTab(subcategory)">{{ subcategory }}</li>
          </ul>
        </div>
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  name: 'tab',
  props: {
    tabs: Object,
    active: String,
    fontSize: { type: String, default: '' }
  },
  components: { ChevronDown },
  computed: {
    console: () => console,
    cssVars() {
      return {
        '--font': this.fontSize
      }
    }
  },
  data() {
    return {
      openedCategories: null,
      currentTab: this.active,
    }
  },

  methods: {
    handleOpenDropdown(tab){
      this.openedCategories === tab ? this.openedCategories = null : this.openedCategories = tab
    },
    changeTab(tab) {
      this.currentTab = tab
      this.$emit('onChange', tab)
    },
  },
}
</script>

<style scoped lang="scss">
    .tab-container {
        background-color: white;
        border-radius: 10px;
    }
    .tab-container-keys {
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    }
    .keys {
      position: relative;
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 22px 24px;
        font-weight: 600;
        color: var(--type-active);
        cursor: pointer;
        font-size: var(--font);
        border-right: 0.5px solid var(--neutral-200);
        span {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 10px;
        }
        .icon {
            stroke: var(--neutral-500) !important;
            width: 20px;
        }

        .dropdown-container {
          display: flex;
          flex-direction: column;
          .dropdown {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            list-style: none; 
            position: absolute;
            padding: 0;
            z-index: 10;
            margin-top: 20px;
            margin-left: -130px !important;   
            background-color: rgb(250, 250, 250);
            box-shadow: 0px 4px 28px rgba(12, 29, 89, 0.1), 0px 24px 48px -8px rgba(12, 29, 89, 0.2);

            li {
              width: 380px;
              color: #525C7A;
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              text-align: left;
              padding: 20px 120px 20px 15px;
              &:hover {
                background: #F4F5FB;
              }
            }
          }
        }
    }
    .disabled {
        color: var(--type-placeholder) !important;
    cursor: not-allowed !important;
        border-bottom: none;
    }
    .tab-content {
        padding: 5px 24px;
    }

    .rotateUp {
      animation: rotateUp .5s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    .rotateDown {
        animation: rotateDown .5s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }

    @keyframes rotateUp {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }

    @keyframes rotateDown {
        0% {
            transform: rotate(180deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }

</style>
